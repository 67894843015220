<template>
  <div class="service-create create-form" v-if="getPermission('door:create')">
    <v-form
      ref="doorForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <h1 class="form-title d-flex">
                    <template v-if="doorId">Update</template>
                    <template v-else>Create</template> Door
                  </h1>
                </v-col>
                <v-col cols="6" class="pt-0 text-right">
                  <v-btn
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit"
                  >
                    <template v-if="doorId">Update</template>
                    <template v-else>Save</template> Door
                  </v-btn>
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    outlined
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <DoorCreate
              :duplicateId="duplicateId"
              :doorId="doorId"
              :barcodeData="barcode"
              :doorCreateData="doorCreate"
              v-on:saveDoor="updateDoor"
            ></DoorCreate>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ServiceMixin from "@/core/plugins/product-mixin";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import DoorCreate from "@/view/pages/door/partials/Create.vue";

export default {
  mixins: [CommonMixin, ServiceMixin],
  name: "service-create",
  title: "Create Service",
  data() {
    return {
      barcode: null,
      barcodeDialog: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      doorCreate: null,
      doorId: null,
      duplicateId: null,
    };
  },
  components: {
    DoorCreate,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateDoor(param) {
      this.doorCreate = param;
    },
    getDoor(doorId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (doorId && doorId > 0) {
          _this.$store
            .dispatch(GET, { url: "door/" + doorId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.doorForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "door";

      if (_this.doorId && _this.doorId > 0) {
        requestType = PUT;
        requestURL = "door/" + _this.doorId;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.doorCreate })
        .then(async (response) => {
          _this.doorId = response.data ? response.data.id : null;

          _this.$router.push(
            _this.getDefaultRoute("door", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getDoor(_this.doorId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Door", route: "door" },
              { title: "Update" },
            ]);
            _this.barcode = response.barcode;
            _this.doorCreate = {
              barcode: response.barcode,
              name: response.name,
              description: response.description,
              product_type: response.product_type,
              images: response.images,
              length: response.length,
              width: response.width,
              height: response.height,

              length_unit: response.length_unit,
              width_unit: response.width_unit,
              height_unit: response.height_unit,

              services: response.services,
              product_images: [], // response.product_images,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getDoor(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            /*  _this.barcode = response.barcode; */

            _this.doorCreate = {
              //barcode: this.barcode,
              name: response.name,

              description: response.description,
              product_type: response.product_type,
              images: response.images,
              length: response.length,
              width: response.width,
              height: response.height,

              length_unit: response.length_unit,
              width_unit: response.width_unit,
              height_unit: response.height_unit,

              services: response.services,
              /*    product_images: response.product_images, */
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.doorId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Door", route: "door" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.door.update" ||
      _this.$route.name == "engineer.door.update"
    ) {
      _this.updateMode = true;

      if (_this.doorId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }
  },
};
</script>
